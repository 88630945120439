import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './ApplierForm.css';
import { IoChevronBackOutline } from "react-icons/io5";

import '../../firebase';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable,uploadBytes } from 'firebase/storage';

// import {  firestore } from '../../firebase'; 

// import { v4 } from 'uuid';
// import { ref, , getDownloadURL } from "firebase/storage";

const ApplierForm = () => {


  const [nameValue, setNameValue] = useState(""); // Full name
  const [phoneValue, setPhoneValue] = useState(""); // Phone number
  const [birthDate, setBirthDate] = useState(""); // Birth date
  const [birthPlace, setBirthPlace] = useState(""); // Birth place
  const [currentProfession, setCurrentProfession] = useState(""); // Current profession
  const [residence, setResidence] = useState(""); // Residence
  const [nationalId, setNationalId] = useState(""); // National ID
  const [passportNumber, setPassportNumber] = useState(""); // Passport number
  const [maritalStatus, setMaritalStatus] = useState(""); // Marital status
  const [religion, setReligion] = useState(""); // Religion
  const [education, setEducation] = useState(""); // Education
  const [specialization, setSpecialization] = useState(""); // Specialization
  const [graduationInstitution, setGraduationInstitution] = useState(""); // Graduation institution
  const [graduationYear, setGraduationYear] = useState(""); // Graduation year
  const [licenseValue, setLicenseValue] = useState(""); // License
  const [licenseTypeValue, setLicenseTypeValue] = useState(""); // License type

  const [fileValue, setFile] = useState(null); // For file input
  const [fileUrl, setFileUrl] = useState(""); // To store download URL


  const [errorMessage, setErrorMessage] = useState("");


  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState('success'); // Can be 'success' or 'failure'

  const handleLicenseTypeChange = (e) => {
    setLicenseTypeValue(e.target.value);
  };

  // const [showPopup, setShowPopup] = useState(false);

  const db = getFirestore();
  const storage = getStorage();



    // State to hold the file name and the message
    const [fileName, setFileName] = useState("");
    const [message, setMessage] = useState("");
  
    const handleFileChange = (e) => {
      const file = e.target.files[0]; // Get the first file from the input
      if (file) {
        setFile(file); // Set the actual file object for upload
        setFileName(file.name); // Set the file name
        setMessage("لقد تم رفع الملف"); // Set the success message
      }
    };
    

  // const handleFileChange = (e) => {
  //   setFile(e.target.files[0]);
  // };

  const setApplierDataToFirestore = async (e) => {

    e.preventDefault();


    // if (!fileValue) {
    //   alert("Please upload a file.");
    //   return;
    // }

    const fileRef = ref(storage, `uploads/${fileValue.name}`);

  

     // Upload file to Firebase Storage
     await uploadBytes(fileRef, fileValue);
     const downloadUrl = await getDownloadURL(fileRef);

     // Save form data and file URL to Firestore

    try {
      await addDoc(collection(db, "applier_Form"), {
        name: nameValue,
        phone: phoneValue,
        birthDate: birthDate,
        birthPlace: birthPlace,
        currentProfession: currentProfession,
        residence: residence,
        nationalId: nationalId,
        passportNumber: passportNumber,
        maritalStatus: maritalStatus,
        religion: religion,
        education: education,
        specialization: specialization,
        graduationInstitution: graduationInstitution,
        graduationYear: graduationYear,
        licenseValue: licenseValue,
        licenseTypeValue: licenseTypeValue,
        fileUrl: downloadUrl, // Save file download URL

      });
      

      setNameValue('');
      setPhoneValue('');
      setBirthDate("");
      setBirthPlace("");
      setCurrentProfession("");
      setResidence("");
      setNationalId("");
      setPassportNumber("");
      setMaritalStatus("");
      setReligion("");
      setEducation("");
      setSpecialization("");
      setGraduationInstitution("");
      setGraduationYear("");
      setLicenseValue("");
      setLicenseTypeValue("");
 setFile(null);
      setFileUrl(downloadUrl);
      
      setPopupType('success');
    } catch (error) {
      console.error("Error: ", error);
      setPopupType('failure');
    }
    setShowPopup(true);


    
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  const handleLicenseChange = (e) => {
    setLicenseValue(e.target.value);
  };


  return (
    <div className="container my-5 form-container">
      <h2 className="text-center mb-4">انشاء سيرتك الذاتية</h2>
      <form dir="rtl"
        method="POST"
        onSubmit={setApplierDataToFirestore}>

        <div className="form-row row">
          <div className="form-group col-md-6">
            <label>الاسم بالكامل</label>
            <input type="text" className="form-control"
              name="name"
              value={nameValue}
              onChange={(e) => setNameValue(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label>رقم الهاتف</label>
            <input type="tel" className="form-control"
              name="phone"
              value={phoneValue}
              onChange={(e) => setPhoneValue(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-6">
            <label>تاريخ الميلاد</label>
            <input type="date" className="form-control"
              name="birthDate"
              value={birthDate} onChange={(e) => setBirthDate(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label>محل الميلاد</label>
            <input type="text" className="form-control"
              name="birthPlace"
              value={birthPlace} onChange={(e) => setBirthPlace(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-6">
            <label>المهنة الحالية بجواز السفر</label>
            <input type="text" className="form-control"
              name="currentProfession"
              value={currentProfession} onChange={(e) => setCurrentProfession(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label>محل الاقامة</label>
            <input type="text" className="form-control"
              name="residence"
              value={residence} onChange={(e) => setResidence(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-6">
            <label>الرقم القومى</label>
            <input type="text" className="form-control"
              name="nationalId"
              value={nationalId} onChange={(e) => setNationalId(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label>رقم جواز السفر</label>
            <input type="text" className="form-control"
              name="passportNumber"
              value={passportNumber} onChange={(e) => setPassportNumber(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-6">
            <label>الحالة الاجتماعية</label>
            <div className="d-flex flex-column">
              <label>
                <input
                  type="radio"
                  name="maritalStatus"
                  value="single"
                  checked={maritalStatus === "single"}
                  onChange={(e) => setMaritalStatus(e.target.value)}
                />{" "}
                أعزب
              </label>
              <label>
                <input
                  type="radio"
                  name="maritalStatus"
                  value="married"
                  checked={maritalStatus === "married"}
                  onChange={(e) => setMaritalStatus(e.target.value)}
                />{" "}
                متزوج
              </label>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label>الديانة</label>
            <div className="d-flex flex-column">
              <label>
                <input
                  type="radio"
                  name="religion"
                  value="Muslim"
                  checked={religion === "Muslim"}
                  onChange={(e) => setReligion(e.target.value)}
                />{" "}
                مسلم
              </label>
              <label>
                <input
                  type="radio"
                  name="religion"
                  value="Christian"
                  checked={religion === "Christian"}
                  onChange={(e) => setReligion(e.target.value)}
                />{" "}
                مسيحي
              </label>
            </div>
          </div>
        </div>


        <div className="form-row row">
          <div className="form-group col-md-3">
            <label>المؤهل الدراسى</label>
            <input type="text" className="form-control"
              name="education"
              value={education} onChange={(e) => setEducation(e.target.value)}
            />
          </div>
          <div className="form-group col-md-3">
            <label>التخصص</label>
            <input type="text" className="form-control"
              name="specialization"
              value={specialization} onChange={(e) => setSpecialization(e.target.value)}
            />
          </div>
          <div className="form-group col-md-3">
            <label>جهة التخرج</label>
            <input type="text" className="form-control"
              name="graduationInstitution"
              value={graduationInstitution}
              onChange={(e) => setGraduationInstitution(e.target.value)}
            />
          </div>
          <div className="form-group col-md-3">
            <label>سنة التخرج</label>
            <input type="number" className="form-control"
              name="graduationYear"
              value={graduationYear}
              onChange={(e) => setGraduationYear(e.target.value)} />
          </div>
        </div>


        <div className="form-row row">
          {/* License Radio Group */}
          <div className="form-group col-md-12">
            <label>هل لديك رخصة؟</label>
            <div className="d-flex flex-column ml-3">
              <label>
                <input
                  type="radio"
                  name="license"
                  value="yes"
                  checked={licenseValue === "yes"}
                  onChange={handleLicenseChange}
                />{" "}
                نعم
              </label>
              <label>
                <input
                  type="radio"
                  name="license"
                  value="no"
                  checked={licenseValue === "no"}
                  onChange={handleLicenseChange}

                />{" "}
                لا
              </label>
            </div>
          </div>

          {/* License Type Radio Group (Conditionally Rendered) */}
          {licenseValue === "yes" && (
            <div className="form-group col-md-12">
              <label>نوع الرخصة</label>
              <div className="d-flex flex-column ml-3">
                <label>
                  <input
                    type="radio"
                    name="licenseType"
                    value="private"
                    checked={licenseTypeValue === "private"}
                    onChange={handleLicenseTypeChange}
                  />{" "}
                  خاصة
                </label>
                <label>
                  <input
                    type="radio"
                    name="licenseType"
                    value="first-class"
                    checked={licenseTypeValue === "first-class"}

                    onChange={handleLicenseTypeChange}
                  />{" "}
                  درجة أولى
                </label>
                <label>
                  <input
                    type="radio"
                    name="licenseType"
                    value="second-class"
                    checked={licenseTypeValue === "second-class"}
                    onChange={handleLicenseTypeChange}
                  />{" "}
                  درجة ثانية
                </label>
                <label>
                  <input
                    type="radio"
                    name="licenseType"
                    value="third-class"
                    checked={licenseTypeValue === "third-class"}
                    onChange={handleLicenseTypeChange}
                  />{" "}
                  درجة ثالثة
                </label>
              </div>
            </div>
          )}
        </div>


{/* 
        <div className="form-row row">
          <div className="form-group col-md-12">
            <label htmlFor="fileInput" className="btn btn-file">
              اختر الملف
            </label>
            <input
              id="fileInput"
              type="file"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
              className="form-control-file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </div> */}


<div>
      <div className="form-row row">
        <div className="form-group col-md-12">
          <label htmlFor="fileInput" className="btn btn-file">
            اختر الملف
          </label>
          <input
            id="fileInput"
            type="file"
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
            className="form-control-file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
      </div>

      {/* Display the file name and message */}
      {fileName && (
        <div>
          <p>اسم الملف: {fileName}</p>
          <p>{message}</p>
        </div>
      )}
    </div>


        <div className="form-row row">
          <div className="col-md-3 d-flex justify-content-center">
            <button type="submit" className="btn submit-btn">
              ارسل طلبك
              <IoChevronBackOutline />

            </button>
          </div>
        </div>

      </form>
      {showPopup && (
        <div className="popup" onClick={handleClosePopup}>
          <div
            className={`popup-content ${popupType === 'success' ? 'popup-success' : 'popup-failure'}`}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
          >
            <span className="close" onClick={handleClosePopup}>&times;</span>
            <p>{popupType === 'success' ? 'تم ارسال البيانات بنجاح!' : 'فشل في إرسال البيانات!'}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplierForm;
