import React from 'react'
import './HeaderDetails.css'

function HeaderDetails() {
    return (
        <section className="hero7_container">
         <div className="container">
                <h1>
                    عن الوظيفة
                </h1>
                <div className='line'>
                    
                </div>
            </div>
    </section>
    )
}

export default HeaderDetails
