// import React, { useState, useEffect } from 'react';
// import { collection, getDocs } from "firebase/firestore";
// import { db } from "../../firebase";
// import './Details.css'
// import { IoChevronBackOutline } from "react-icons/io5";

// function Details() {

//     const [jobs, setJobs] = useState([]);
//     const [currentPage, setCurrentPage] = useState(1);
  
//     const fetchJobs = async () => {
//       try {
//         const jobsCollection = collection(db, "jobs");
//         const jobsSnapshot = await getDocs(jobsCollection);
//         const jobsList = jobsSnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
  
//         setJobs(jobsList);
//       } catch (error) {
//         console.error("Error fetching jobs:", error);
//       }
//     };
  
//     useEffect(() => {
//       fetchJobs();
//     }, []);
//     return (
//         <section className='job-details' dir='rtl'>
//             <div className='container' key={job.id}>
//                 <div className='job-cont'>
//                     <h2>وصف الوظيفة</h2>
//                     <div className="line"></div>
//                     <p>{job.jobDescription}</p>
//                 </div>
//                 <div className='job-cont'>
//                     <h2>متطلبات الوظيفة</h2>
//                     <div className="line"></div>
//                     <p>

//                     </p>
//                 </div>
//                 <div className='job-cont'>    
//                     <h2>مميزات الوظيفة</h2>
//                     <div className="line"></div>
//                     <p>

//                     </p>
//                 </div>
//                 <div className='job-cont'>    
//                     <h2>معلومات الوظيفة</h2>
//                     <div className="line"></div>
//                     <p>

//                     </p>
//                 </div>  

//                 <button className='applyForJob'>
//                     سجل فى الوظيفة
//                     <IoChevronBackOutline />
//                 </button>


//             </div>
//         </section>
//     )
// }

// export default Details


import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import './Details.css';
import { IoChevronBackOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

function Details() {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);

  const fetchJob = async () => {
    try {
      const jobRef = doc(db, "jobs", jobId);
      const jobSnapshot = await getDoc(jobRef);
      if (jobSnapshot.exists()) {
        setJob({ id: jobSnapshot.id, ...jobSnapshot.data() });
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching job:", error);
    }
  };

  useEffect(() => {
    fetchJob();
  }, [jobId]);


    // Helper function to render text with line breaks
    const renderTextWithBreaks = (text) => {
        return text ? text.split('\n \n').map((line, index) => (
          <p key={index}>{line}</p>
        )) : <p>No information available</p>;
      };


  if (!job) {
    return <p>Loading...</p>;
  }

  return (
    <section className='job-details' dir='rtl'>
      <div className='container'>
        <div className='job-cont'>
          <h2>وصف الوظيفة</h2>
          <div className="line"></div>
          <p>{renderTextWithBreaks(job.jobDescription)}</p>
        </div>
        <div className='job-cont'>
          <h2>متطلبات الوظيفة</h2>
          <div className="line"></div>
          <p>{renderTextWithBreaks(job.jobRequirements || "No requirements listed")}</p>
        </div>
        <div className='job-cont'>
          <h2>مميزات الوظيفة</h2>
          <div className="line"></div>
          <p>{renderTextWithBreaks(job.jobFeatures || "No benefits listed")}</p>
        </div>
        <div className='job-cont'>
          <h2>معلومات الوظيفة</h2>
          <div className="line"></div>
          <p>{renderTextWithBreaks(job.jobInfo || "No additional info listed")}</p>
        </div>  
        <Link to='/applier' className='applyForJob'>
          سجل فى الوظيفة
          <IoChevronBackOutline />
        </Link>
      </div>
    </section>
  );
}

export default Details;
